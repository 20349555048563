<template>
	<div class="reimburse">
		<frame :top1="top1" :top2="top2">
			<tabbar :tabbar="tabbar" @cuts='cut'></tabbar>
			<search @search='search'></search>
			<lists :titles='titles' :flag='flag' :contentC='datas' @add='refresh()' @del='refresh()'></lists>
			<page :num='num'></page>
		</frame>
	</div>
</template>

<script>
	import frame from '../public/Frame.vue';
	import search from './componReimburse/Search.vue';
	import lists from './componReimburse/List.vue';
	import tabbar from './componReimburse/TabbarList.vue';
	import page from '../commodity/componList/Page.vue';
	export default {
		name: 'reimburse',
		data() {
			return {
				top1: '5-6',
				top2: ['5'],
				titles: ['服务单号', '申请时间', '用户账号', '退款金额', '申请状态', '处理时间', '操作'],
				flag: 1,
				datas: null,
				tabbar: ['全部申请', '待处理', '已处理', '已拒绝'],
				num: 100
			}
		},
		components: {
			frame,
			search,
			tabbar,
			page,
			lists
		},
		created() {
			let token = this.$storage.getLocal('token');
			this.$request.refundOrderList({
				token,
				type: this.$storage.getLocal('type'),
				order_sn: '',
				page: 1,
				limit: 10,
				status: 'whole',
				time: '',
			}).then(res => {
				if (res.code == 0) {
					this.datas = res.data.data
					this.num = res.data.cunt
				}
			})
		},
		methods: {
			cut(item) {
				let set = 'whole'
				if (item == '待处理') {
					set = 6
				} else if (item == '已处理') {
					set = 7
				} else if (item == '已拒绝') {
					set = 8
				} else if (item == '全部申请') {
					set = 'whole'
				}
				let token = this.$storage.getLocal('token');
				this.$request.refundOrderList({
					token,
					type: this.$storage.getLocal('type'),
					order_sn: '',
					page: 1,
					limit: 10,
					status: set,
					time: '',
				}).then(res => {
					if (res.code == 0) {
						this.datas = res.data.data
						this.num = res.data.cunt
					}
				})
			},
			search(item) {
				let newTime = ''
				if(item.date){
					newTime = item.date.getFullYear() + '-' + (item.date.getMonth() + 1) + '-' + item.date.getDate()
				}
				
				let token = this.$storage.getLocal('token');
				this.$request.refundOrderList({
					token,
					type: this.$storage.getLocal('type'),
					order_sn: item.orderId,
					page: 1,
					limit: 10,
					status: item.select,
					time: newTime,
				}).then(res => {
					if (res.code == 0) {
						this.datas = res.data.data
						this.num = res.data.cunt
					}
				})
			}
		}
	}
</script>

<style scoped="scoped">
</style>
